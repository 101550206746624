import React, { useEffect, useRef, useState } from 'react'
import '../css/Alphabet.css'
import SpeakerButton from './SpeakerButton'
import ExpandedImages from './ExpandedImages'

const Alphabet = ({ alphabet, alphabetStore, setalphabetStore, infoOnClick, closeButtonClick }) => {

  const [isMobile, setisMobile] = useState(window.innerWidth <= 820);

  let infoButtonTop = 16.5
  if (isMobile) {
    infoButtonTop = 8
  }

  const [alphabetContainerClass, setalphabetContainerClass] = useState('alphabetContainer')
  const [speakerStyle, setspeakerStyle] = useState({

    height: '30%',
    width: '30%',
    position: 'absolute',
    cursor: 'pointer',
    transition: "0.2s",
    backgroundColor: 'rgb(0, 0, 0,0)'
  })


  const onMouseEnter = () => {
    if (alphabet.isExpandedView) {
      return;
    }

    let temp = [...alphabetStore]
    temp[alphabet.index].fontSize = 5.8
    // temp[alphabet.index].boxShadow = '2px 2px 5px 5px black'

    setalphabetContainerClass('alphabet alphabetContainer-hover')
    setalphabetStore(temp)
  }

  const onMouseLeave = () => {
    if (alphabet.isExpandedView || !audioRef.current.paused) {
      return;
    }
    let temp = [...alphabetStore]
    temp[alphabet.index].fontSize = 3.8
    // temp[alphabet.index].boxShadow = '2px 2px 5px 1px black'
    setalphabetContainerClass('alphabetContainer')
    setalphabetStore(temp)

  }

  const audioRef = React.useRef(null);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();

      if (alphabet.isExpandedView) {
        return;
      }
      setalphabetStore(prevState => {
        const updatedStore = [...prevState];
        updatedStore[alphabet.index].fontSize = 7.8;
        updatedStore[alphabet.index].boxShadow = '2px 2px 5px 10px black'
        // setalphabetContainerClass('alphabetContainer rainbow-bg')
        return updatedStore;
      });

      audioRef.current.addEventListener('ended', () => {
        if (alphabet.isExpandedView) {
          return;
        }
        setalphabetStore(prevState => {
          const updatedStore = [...prevState];
          updatedStore[alphabet.index].fontSize = 3.8;
          updatedStore[alphabet.index].boxShadow = '2px 2px 5px 1px black'
          // setalphabetContainerClass('alphabetContainer')

          return updatedStore;
        });

      });
    }
  };

  const videoRef = useRef(null);
  const [videoStyle, setvideoStyle] = useState({
    // border: '1px solid black',
    position: 'absolute',
    borderRadius: 10,
    width: '30vw', height: '30vh',
    clipPath: "inset(1px 1px)"
  })

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 2;
    }
  })

  let imgs = {
    'A': ['apple.svg', 'airplane.svg', 'ant.svg', 'angry.svg'],
    'B': ['ball.svg', 'bat.svg', 'bed.svg', 'brain.svg', 'bandage.svg', 'bison.svg'],
    'C': ['cat.svg', 'car.svg', 'cake.svg', 'chocolate.svg', 'crow.svg'],
    'D': ['dog.svg', 'doll.svg', 'duck.svg', 'doctor.svg', 'dragon.svg'],
    'E': ['elephant.svg', 'egg.svg', 'ear.svg', 'eye.svg'],
    'F': ['fish.svg', 'fan.svg', 'frog.svg'],
    'G': ['goat.svg', 'grapes.svg', 'gun.svg', 'glasses.svg', 'gorilla.svg'],
    'H': ['hat.svg', 'hospital.svg', 'hand.svg', 'house.svg', 'happy.svg'],
    'I': ['ice.svg', 'igloo.svg', 'island.svg'],
    'J': ['joker.svg', 'jacket.svg', 'juice.svg'],
    'K': ['kite.svg', 'key.svg', 'king.svg'],
    'L': ['lion.svg', 'lamp.svg', 'leaf.svg'],
    'M': ['mouse.svg', 'man.svg', 'moon.svg', 'mayonnaise.svg'],
    'N': ['net.svg', 'nails.svg', 'nose.svg', 'nurse.svg', 'ninja.svg'],
    'O': ['orange.svg', 'owl.svg', 'octopus.svg'],
    'P': ['pig.svg', 'pen.svg', 'police.svg', 'pan.svg', 'pancakes.svg', 'potato.svg'],
    'Q': ['queen.svg', 'question.svg', 'quill.svg'],
    'R': ['rabbit.svg', 'ring.svg', 'rocket.svg', 'RPG.svg'],
    'S': ['sun.svg', 'sad.svg', 'snake.svg', 'syringe.svg', 'stethoscope.svg', 'signboard.svg', 'stork.svg', 'soup.svg'],
    'T': ['tennis.svg', 'tree.svg', 'tiger.svg'],
    'U': ['umbrella.svg', 'unicorn.svg', 'underwear.svg'],
    'V': ['van.svg', 'vase.svg', 'violin.svg'],
    'W': ['watermelon.svg', 'woman.svg', 'wolf.svg'],
    'X': ['box.svg', 'xylophone.svg', 'fox.svg'],
    'Y': ['yarn.svg', 'yoyo.svg', 'yolk.svg'],
    'Z': ['zebra.svg', 'zombie.svg', 'zero.svg']
  }

  const loadImages = () => {
    imgs[alphabet.uppercase].forEach(x => {
      const img = new Image();
      img.src = `images/${alphabet.uppercase}/${x}`

    });
  }

  return (
    <div className={alphabetContainerClass} style={{
      boxShadow: alphabet.boxShadow,
      // transition: `${alphabet.boxShadow === '2px 2px 5px 5px black' ? '0.1s' : '0.5s'}`,
      top: `${alphabet.top}vh`,
      left: `${alphabet.left}vw`,
      position: 'absolute',
      width: `${alphabet.width}vw`,
      height: `${alphabet.height}vh`,
      border: `${alphabet.isVowel ? '2px solid red' : null}`,
    }}>
      <SpeakerButton onClick={playAudio} style={{
        ...speakerStyle,
        display: alphabet.closeButtonDisplay,
        top: `${alphabet.speakerTop}vh`,
        left: `${alphabet.speakerLeft}vw`
      }} />

      <div className='closeButton' onClick={closeButtonClick} style={{
        fontSize: '3rem',
        position: 'absolute',
        right: isMobile ? '3vw' : '1vw',
        top: '-1vh',
        display: alphabet.closeButtonDisplay
      }}>x</div>

      <video style={{ ...videoStyle, display: alphabet.closeButtonDisplay, top: `${alphabet.videoTop}vh`, left: `${alphabet.videoLeft}vw` }} ref={videoRef} loop autoPlay muted>
        <source src={`./videos/${alphabet.uppercase}.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <img style={{ ...videoStyle, display: alphabet.closeButtonDisplay }} src={`./videos/${alphabet.uppercase}.gif`}> */}
      {/* </img> */}

      <div
        onClick={playAudio}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className='alphabet' style={{
          fontSize: `${alphabet.fontSize}vw`,
          cursor: alphabet.cursor,
          position: 'absolute',
          top: `${alphabet.letterTop}vh`,
          left: `${alphabet.letterLeft}vw`,
          height: `${alphabet.letterHeight}`,
          width: `${alphabet.letterWidth}`
        }}>
        <div>
          {alphabet.uppercase}{alphabet.lowercase}
        </div>
        {/* {
          alphabet.isVowel ?
            <div style={{
              position: 'absolute',
              fontSize: '0.8vw',
              top: 0,
              right: '0.2vw'
            }}>
              {'vowel'}
            </div> : null
        } */}
      </div>

      <audio ref={audioRef}>
        <source src={`./audio/${alphabet.uppercase}.mp3`} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>

      <ExpandedImages imgs={imgs} display={alphabet.closeButtonDisplay} alphabet={alphabet.uppercase} top={alphabet.expandedImagesTop} left={alphabet.expandedImagesLeft} />

      <div className='info'
        onClick={() => { infoOnClick(alphabet.uppercase); loadImages() }}
        style={{
          top: `${alphabet.infoButtonTop}vh`,
          position: 'absolute',
        }}>
        <div className='infoButton' style={{
          display: alphabet.infoDisplay,
        }}>
          <p>i</p>
        </div>
      </div>

    </div >

  )
}

export default Alphabet