import React, { useEffect, useState } from 'react'
import SlideshowArrow from './SlideshowArrow'
// import AvsAnSimple from './AvsAn'

const ExpandedImages = ({ display, alphabet, top, left, imgs }) => {
    const [isMobile, setisMobile] = useState(window.innerWidth <= 820);


    const [currIndex, setcurrIndex] = useState(0)

    const prev = () => {
        if (displayAlt) {
            setdisplayAlt(false)
        }
        if (currIndex < 1) {
            setcurrIndex((imgs[alphabet].length - 1))
            return;
        }
        setcurrIndex(currIndex - 1)
    }

    const next = () => {
        if (displayAlt) {
            setdisplayAlt(false)
        }
        if (currIndex > (imgs[alphabet].length - 2)) {
            setcurrIndex(0)
            return;
        }
        setcurrIndex(currIndex + 1)
    }

    useEffect(() => {
        // console.log(imgs[alphabet]);
        // imgs[alphabet].forEach(x => {
        //     const img = new Image();
        //     img.src = `images/${alphabet}/${x}`
        //     // console.log(x);
        //     // console.log(`/images/${alphabet}/${x}.svg`);

        // });
        console.log('helllo');

    }, []);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let altImage = {
        'chocolate': 'gintoki.jpg',
        'dog': 'fjord.png',
        'doll': 'matryoshka.svg',
        'glasses': 'shinpachi.jpg',
        'gorilla': 'gorillas.gif',
        'crow': 'itachi.png',
        'mayonnaise': 'hijikata.jpg',
        'police': 'shinsengumi.jpg',
        'RPG': 'okita.jpg',
        'signboard': 'elizabeth.jpg',
        'umbrella': 'kagura.png',
        'ninja': 'naruto.jpg',
        'dragon': 'smaug.avif',
        'eye': 'Eye of Sauron.jpg',
        'leaf': 'konoha.png',
        'potato': 'draniki.jpg',
        'ring': 'One Ring.png'
    }

    const [displayAlt, setdisplayAlt] = useState(false)
    const imageTitle = isMobile ? '2rem' : '3rem'

    const checkAlt = () => {
        if (displayAlt) {
            setdisplayAlt(false)
            return;
        }

        if (imgs[alphabet][currIndex].slice(0, -4) in altImage) {
            setdisplayAlt(true)
        }
    }
    return (
        <div style={{
            height: isMobile ? '35vh' : '30vh',
            width: isMobile ? '35vw' : '30vw',
            textAlign: 'center',
            position: 'absolute',
            top: `${top}vh`,
            left: `${left}vw`,
            display: display === 'block' ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <SlideshowArrow left={true} polyStyle={{ fill: 'white' }} onClick={prev} />

            <img
                alt='noalt'
                onClick={checkAlt}
                className='img' style={
                    displayAlt && (altImage[imgs[alphabet][currIndex].slice(0, -4)] === 'fjord.png' || altImage[imgs[alphabet][currIndex].slice(0, -4)] === 'itachi.png' || altImage[imgs[alphabet][currIndex].slice(0, -4)] === 'kagura.png') ?
                        isMobile ? {
                            height: '20vh',
                            width: '40vw',
                        } :
                            {
                                height: '35vh',
                                width: '19vw',
                            } :
                        isMobile && displayAlt ? {
                            height: '10vh',
                            width: '45vw',
                        } :
                            {
                                height: '25vh',
                                width: '25vw',
                            }
                } src={displayAlt ? `./images/${alphabet}/${altImage[imgs[alphabet][currIndex].slice(0, -4)]}` : `./images/${alphabet}/${imgs[alphabet][currIndex]}`}></img>
            <div style={{
                position: 'absolute',
                bottom: isMobile ? `${top - 35}vh` : `${top - 40}vh`,

            }}>
                {/* <span style={{ color: 'black', fontWeight: 'bold', fontSize: '2vw' }}>{AvsAnSimple.query(imgs[alphabet][currIndex])}</span>  */}
                {
                    displayAlt ? <span style={{ color: 'black', fontWeight: 'bold', fontSize: imageTitle }}>
                        {capitalizeFirstLetter(altImage[imgs[alphabet][currIndex].slice(0, -4)].slice(0, -4))}
                    </span> :
                        imgs[alphabet][currIndex].slice(0, -4).split('').map((x, i) => {
                            return <span key={i}
                                style={x.toLowerCase() === alphabet.toLowerCase() ? { color: 'red', fontWeight: 'bold', fontSize: imageTitle } : { color: 'black', fontWeight: 'bold', fontSize: imageTitle }}>{
                                    i === 0 ? x.toUpperCase() : x
                                }</span>
                        })
                }</div>
            <SlideshowArrow left={false} polyStyle={{ fill: 'white' }} style={{ rotate: '180deg' }} onClick={next} />
        </div >
    )
}

export default ExpandedImages