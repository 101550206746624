import React from 'react';

import AlphabetsPage from './components/AlphabetsPage';
import './App.css'; // Import custom global styles

function App() {
  return (
    <div >
      <AlphabetsPage />
    </div>
  );
}

export default App;
