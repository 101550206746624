import React, { useState } from 'react'
import '../css/Heading.css';


const Heading = () => {
    const [isMobile, setisMobile] = useState(window.innerWidth <= 820)
    const [lanText, setlanText] = useState('Lan')
    const [barText, setbarText] = useState('Bar')

    const [lanStyle, setlanStyle] = useState(isMobile ? {
        top: '2vh',
        left: '-15vw',
    } : {
        top: '2vh',
        left: '-2vw',
    })
    const [barStyle, setbarStyle] = useState({
        top: '2vh',
        left: isMobile ? '2vw' : '1vw'
    })
    const [headingStyle, setheadingStyle] = useState({
        top: '1vh',
        left: '50vw'
    })
    const [h1Style, seth1Style] = useState({
        fontFamily: 'LXGmodded',
        cursor: 'pointer',
        fontSize: isMobile ? '11vw' : '2vw'
    })
    const [headingContainerStyle, setheadingContainerStyle] = useState({
        display: 'block',
        opacity: '0',
        height: '10vh',
        width: isMobile ? '80vw' : '40vw',
        transform: 'translate(-50%, 0%)',
    })

    const [overlayStyle, setoverlayStyle] = useState({
        display: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0)'
    })

    const [gaugeStyle, setgaugeStyle] = useState({
        position: 'absolute',
        display: 'block',
        left: '-5.0vw',
        top: '2vh',
        opacity: 0
    })
    const [rierStyle, setrierStyle] = useState({
        position: 'absolute',
        display: 'block',
        left: '3.9vw',
        top: '2vh',
        opacity: 0
    })

    const [textStyle, settextStyle] = useState({ display: 'none', opacity: 1 })
    const [isAnimActive, setisAnimActive] = useState(false)

    const viewLanBar = () => {
        if (isAnimActive) {
            return;
        }
        setisAnimActive(true)
        setTimeout(() => {
            setisAnimActive(false)
        }, 2000);

        setheadingStyle({
            ...headingStyle,
            top: '20vh',
        })
        setheadingContainerStyle({
            ...headingContainerStyle,
            opacity: 1
        })

        setTimeout(() => {
            setheadingContainerStyle({
                ...headingContainerStyle,
                opacity: 1,
                height: '60vh'
            })
        }, 400);

        if (isMobile) {
            seth1Style({ ...h1Style, fontSize: '6vw' })
        }
        setTimeout(() => {

            setlanStyle({
                ...lanStyle,
                left: isMobile ? '-24vw' : '-7vw'
            })
            setbarStyle({
                ...barStyle,
                left: isMobile ? '3vw' : '2vw'
            })
            setTimeout(() => {
                setgaugeStyle({
                    ...gaugeStyle,
                    opacity: 1,
                    left: isMobile ? '-15vw' : '-4vw'
                })
                setrierStyle({
                    ...rierStyle,
                    opacity: 1,
                    left: isMobile ? '12vw' : '4.9vw'
                })
                settextStyle({
                    ...textStyle,
                    display: 'block',
                    opacity: 0
                })
                setTimeout(() => {
                    settextStyle({
                        ...textStyle,
                        display: 'block',
                        opacity: 1
                    })
                }, 200);

            }, 200);
        }, 1000);

        setoverlayStyle({
            ...overlayStyle,
            display: 'block'
        })
        setTimeout(() => {
            setoverlayStyle({
                ...overlayStyle,
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.85)'
            })
        }, 50);

    }

    const closeLanBar = () => {
        if (isAnimActive) {
            return;
        }
        if (isMobile) {
            seth1Style({ ...h1Style, fontSize: '11vw' })
        }
        setisAnimActive(true)
        setTimeout(() => {
            setisAnimActive(false)
        }, 2000);

        setlanStyle({
            top: '2vh',
            left: isMobile ? '-15vw' : '-2vw',
        })
        setbarStyle({
            top: '2vh',
            left: isMobile ? '2vw' : '1vw'
        })
        setgaugeStyle({
            ...gaugeStyle,
            opacity: 0
        })
        setrierStyle({
            ...rierStyle,
            opacity: 0
        })

        setheadingStyle({
            ...headingStyle,
            top: '1vh'
        })
        setheadingContainerStyle({
            ...headingContainerStyle,
            height: '10vh',
            opacity: 0
        })
        settextStyle(
            {
                ...textStyle,
                opacity: 0
            }
        )
        setTimeout(() => {
            settextStyle(
                {
                    ...textStyle,
                    opacity: 0,
                    display: 'none'
                }
            )
            setoverlayStyle({
                ...overlayStyle,
                backgroundColor: 'rgb(0,0,0,0)'
            })
            setTimeout(() => {
                setoverlayStyle({
                    ...overlayStyle,
                    backgroundColor: 'rgb(0,0,0,0)',
                    display: 'none'
                })

            }, 1000);
        }, 200);

    }

    const headingHover = () => {
        if (h1Style.color === 'rgb(115, 192, 255)') {
            seth1Style({ ...h1Style, color: 'rgb(0,0,0)' })
            return;
        }

        seth1Style({ ...h1Style, color: 'rgb(115, 192, 255)' })
    }

    return (
        <div className='heading' style={headingStyle}>
            <span onClick={closeLanBar} className='headingClose' style={{
                display: headingContainerStyle.opacity === 1 ? 'block' : 'none',
                fontSize: isMobile ? '10vw' : '1.8vw'
            }}>X</span>

            <div className='headingContainer' style={headingContainerStyle} />
            <div className='headingOverlay' style={overlayStyle} />

            <span style={h1Style} onClick={viewLanBar} className='headingText' onMouseEnter={headingHover} onMouseLeave={headingHover} >
                <span className='headingText' style={{ ...lanStyle }}>{lanText}</span>
                <span className='headingText' style={gaugeStyle}>gauge</span>
                <span className='headingText' style={{ ...barStyle }}>{barText}</span>
                <span className='headingText' style={rierStyle}>rier</span>
            </span>

            <p className='para' style={isMobile ? {
                fontSize: '4vw',
                top: '12vh',
                left: '-35vw',
                ...textStyle
            } :
                {
                    fontSize: '1.5vw',
                    top: '10vh',
                    ...textStyle
                }}>"difficulty in communicating due to the lack of a shared language"</p>

            <p className='para' style={isMobile ? {
                fontSize: '2.8vw',
                top: '19vh',
                left: '-17vw',
                ...textStyle
            } :
                {
                    fontSize: '1.2vw',
                    top: '20vh',
                    left: '-5vw',
                    ...textStyle
                }}>- dictionary.com</p>

            <p className='para' style={isMobile ? {
                fontSize: '3vw',
                top: '35vh',
                left: '-35vw',
                ...textStyle
            } :
                {
                    fontSize: '1vw',
                    top: '35vh',
                    ...textStyle
                }}>A language barrier is a figurative phrase used primarily to refer to linguistic barriers to communication, i.e. the difficulties in communication experienced by people or groups originally speaking different languages, or even dialects in some cases.</p>

        </div >
    )
}

export default Heading