import React, { useEffect, useState } from 'react';
import '../css/reset.css';
import '../css/AlphabetsPage.css';
import Alphabet from './Alphabet';
import SlideshowArrow from './SlideshowArrow';
import Heading from './Heading';
// import SlideshowArrow from './SlideshowArrow';

function HomePage() {
  useEffect(() => {
    document.title = 'LanBar'
  }, [])

  // const isMobile = window.innerWidth <= 820;
  const [isMobile, setisMobile] = useState(window.innerWidth <= 820)

  let initial = [];
  let top = 4;
  let left = 3;
  let width = 10;
  let height = 16;
  let letterTop = 0;
  let letterLeft = 0;
  let letterHeight = '16vh';
  let letterWidth = '10vw';
  let infoButtonTop = 16.5;
  let videoTop = 36;
  let videoLeft = 3;
  let expandedImagesTop = 35;
  let expandedImagesLeft = 42;

  if (isMobile) {
    width = 15;
    letterHeight = '100%';
    letterWidth = '100%';
    height = 7;
    left = 0
    infoButtonTop = 8;
  }

  let justifyContent = 'center';
  let alignItems = 'center';
  let position = 'static';

  for (let i = 0; i < 26; i++) {
    const uppercase = String.fromCharCode(65 + i); // 'A' is 65 in ASCII
    const lowercase = String.fromCharCode(97 + i); // 'a' is 97 in ASCII
    // letters.push(uppercase + lowercase);
    // positions.push([top, left])
    let isVowel = false;
    if ('AEIOU'.includes(uppercase)) {
      isVowel = true;
    }
    initial.push({
      index: i,
      uppercase: uppercase,
      lowercase: lowercase,
      top: top,
      left: left,
      justifyContent,
      alignItems,
      position,
      containerLeft: -2,
      width: width,
      height: height,
      fontSize: 3.8,
      cursor: "pointer",
      infoDisplay: 'block',
      isExpandedView: false,
      letterPosition: 'absolute',
      letterTop: letterTop,
      letterLeft: letterLeft,
      closeButtonDisplay: 'none',
      speakerTop: 10,
      speakerLeft: 10,
      isVowel: isVowel,
      letterHeight,
      letterWidth,
      infoButtonTop,
      videoTop,
      videoLeft,
      expandedImagesTop,
      expandedImagesLeft,
    })

    if (isMobile) {

      left += 17
      if (uppercase === 'E') {
        top = 15
        left = 0
      }
      if (uppercase === 'J') {
        top += 11
        left = 0
      }
      if (uppercase === 'O') {
        top += 11
        left = 0

      }
      if (uppercase === 'T') {
        top += 11
        left = 0

      }

      if (uppercase === 'Y') {
        top += 11
        left = 34.5
      }

    } else {


      // top += 20
      left += 11
      if (uppercase === 'G') {
        top = 25
        left = 3
      }
      if (uppercase === 'N') {
        top += 21
        left = 3
      }
      if (uppercase === 'U') {
        top += 21
        left = 14

      }
    }

  }

  const [alphabetStore, setalphabetStore] = useState(initial)

  const expandLetter = (letter) => {
    let temp = [...alphabetStore]
    if (isMobile) {
      setalphabetContainerStyle({ ...alphabetsContainerStyle, top: '50%' })
    }

    for (const i in temp) {
      if (temp[i].uppercase !== letter) {
        if (['A', 'B', 'C', 'D', 'H', 'I', 'J', 'O', 'P', 'Q', 'R', 'V', 'W'].includes(temp[i].uppercase)) {
          temp[i].left = temp[i].left - 100
        }
        else {
          temp[i].left = temp[i].left + 100
        }
      }
      else {
        temp[i].top = 5
        temp[i].left = 3
        temp[i].position = 'absolute'
        temp[i].width = 75
        temp[i].height = 75
        temp[i].cursor = 'auto'
        temp[i].infoDisplay = 'none'
        temp[i].isExpandedView = true
        temp[i].letterPosition = 'absolute'
        temp[i].fontSize = 12

        temp[i].closeButtonDisplay = 'block'
        temp[i].letterTop = 12
        temp[i].letterLeft = 12
        temp[i].speakerTop = 10
        temp[i].speakerLeft = 45
        temp[i].letterHeight = '10vh'
        temp[i].letterWidth = '16vw'

        if (isMobile) {
          console.log('ismobile');
          temp[i].letterTop = 5
          temp[i].letterLeft = 30
          temp[i].speakerTop = 15
          temp[i].speakerLeft = 28
          temp[i].videoTop = 26
          temp[i].videoLeft = 24
          temp[i].expandedImagesTop = 40
          temp[i].expandedImagesLeft = 20

        }
      }
    }

    setalphabetStore(temp)


  }

  const closeExpandedView = () => {
    if (isMobile) {
      setalphabetContainerStyle({ ...alphabetsContainerStyle, top: '55%' })
    }
    setalphabetStore(initial)
    if (isSlideshowMode) {
      setisSlideshowMode(false)
      setslideshowIndex(0)
    }
  }

  const boxesFontSize = isMobile ? '3vw' : '0.8vw'
  const boxes = [
    {
      name: 'Random',
      fontSize: boxesFontSize,
      color: 'black'

    },
    {
      name: 'Quiz',
      fontSize: boxesFontSize,
      color: 'black'

    },
    {
      name: 'Slideshow',
      fontSize: boxesFontSize,
      color: 'black'
    },
    {
      name: '?',
      fontSize: boxesFontSize,
      color: 'black'
    }
  ]

  const [finalBoxes, setfinalBoxes] = useState(boxes)

  const triggerBox = (index, value) => {
    setfinalBoxes(prev => {
      const newBoxes = [...prev];
      newBoxes[index] = {
        ...newBoxes[index],
        name: value,
        fontSize: isMobile ? '4vw' : '1vw',
        color: 'white'
      };
      return newBoxes;
    });
  }

  const box1Hover = (option) => {
    switch (option) {
      case 'Random':

        if (finalBoxes[0].name === 'Random')
          triggerBox(0, 'Random!')
        else
          setfinalBoxes(boxes)
        break;

      case 'Quiz':
        if (finalBoxes[1].name === 'Quiz')
          triggerBox(1, 'Quiz?')
        else
          setfinalBoxes(boxes)
        break;

      case 'Slideshow':
        if (finalBoxes[2].name === 'Slideshow')
          triggerBox(2, 'Slideshow ')
        else
          setfinalBoxes(boxes)
        break;

      case '?':
        break;
    }
  }

  const boxHandler = (option) => {
    switch (option) {
      case 'Random':
        randomLetter()
        break;

      case 'Quiz':
        console.log('call quiz');
        break;

      case 'Slideshow':
        slideshow()
        break;

      case '?':
        console.log('?');
        break;
    }
  }


  const randomLetter = () => {
    const rand = Math.floor(Math.random() * 26);
    expandLetter(alphabetStore[rand].uppercase)
  }

  const [isSlideshowMode, setisSlideshowMode] = useState(false)
  const [slideshowIndex, setslideshowIndex] = useState(0)
  const slideshow = () => {
    slideshowExpand(alphabetStore[0].uppercase)
    setisSlideshowMode(true)
  }
  const slideshowExpand = (letter) => {
    let temp = [...alphabetStore]

    if (isMobile) {
      setalphabetContainerStyle({ ...alphabetsContainerStyle, top: '50%' })
    }

    for (let i in temp) {

      if (temp[i].uppercase === letter) {

        setSlideshowExpanded(temp, i, 5, 3)

      }
      else {
        if ('BCD'.includes(temp[i].uppercase)) {
          temp[i].left = 250
          setTimeout(() => {
            setSlideshowExpanded(temp, i, 5, 250)
            setalphabetStore(temp)
          }, 200);
        }
        else if ('XYZ'.includes(temp[i].uppercase)) {
          temp[i].left = -250
          setTimeout(() => {
            setSlideshowExpanded(temp, i, 5, -250)
            setalphabetStore(temp)
          }, 200);
        }
        else {
          temp[i].left = 250
          setTimeout(() => {
            setSlideshowExpanded(temp, i, 5, 250)
            setalphabetStore(temp)
          }, 200);

        }
      }

    }

    setalphabetStore(temp)
  }

  const setSlideshowExpanded = (temp, i, top, left) => {

    temp[i].top = top
    temp[i].left = left
    temp[i].position = 'absolute'
    temp[i].width = 75
    temp[i].height = 75
    temp[i].cursor = 'auto'
    temp[i].infoDisplay = 'none'
    temp[i].isExpandedView = true
    temp[i].letterPosition = 'absolute'
    temp[i].fontSize = 12
    temp[i].closeButtonDisplay = 'block'
    temp[i].letterTop = 12
    temp[i].letterLeft = 12
    temp[i].speakerTop = 10
    temp[i].speakerLeft = 45
    temp[i].letterHeight = '10vh'
    temp[i].letterWidth = '16vw'

    if (isMobile) {
      temp[i].letterTop = 5
      temp[i].letterLeft = 30
      temp[i].speakerTop = 15
      temp[i].speakerLeft = 28
      temp[i].videoTop = 26
      temp[i].videoLeft = 24
      temp[i].expandedImagesTop = 43
      temp[i].expandedImagesLeft = 23
    }
  }

  const nextSlide = () => {
    let temp = [...alphabetStore];
    let newIndex = (slideshowIndex + 1) % 26; // Cycle through the alphabet (0-25)

    // Slide the current letter out of view to the left (-250vw)
    setSlideshowExpanded(temp, slideshowIndex, 5, -250);

    // Bring the next letter onto the screen from the right (3vw)
    setSlideshowExpanded(temp, newIndex, 5, 3);

    // Ensure the letter after the new one is positioned off-screen on the right (1000vw)
    // and then slides closer to the screen (250vw)
    setSlideshowExpanded(temp, (newIndex + 4) % 26, 1000, 250);

    // Use a consistent delayed animation for the fourth letter sliding in
    setTimeout(() => {
      setSlideshowExpanded(temp, (newIndex + 4) % 26, 5, 250);
      setalphabetStore(temp);
    }, 200);

    // Update the slideshowIndex for the next click
    setslideshowIndex(newIndex);
    setalphabetStore(temp);
  };

  const prevSlide = () => {
    let temp = [...alphabetStore];
    let newIndex = (slideshowIndex - 1 + 26) % 26; // Cycle through the alphabet backwards (0-25)

    // Slide the current letter out of view to the right (250vw)
    setSlideshowExpanded(temp, slideshowIndex, 5, 250);

    // Bring the previous letter onto the screen from the left (-3vw)
    setSlideshowExpanded(temp, newIndex, 5, 3);

    // Ensure the letter before the new one is positioned off-screen on the left (-1000vw)
    let previousLetterIndex = (newIndex - 2 + 26) % 26; // Correctly index the previous letter
    setSlideshowExpanded(temp, previousLetterIndex, 1000, -250);

    // Update the slideshowIndex and alphabetStore state in one go
    setslideshowIndex(newIndex);
    setalphabetStore(temp);

    // Adjust the index to make sure no letter is skipped
    setTimeout(() => {
      // Update the position of the previous letter
      let updatedTemp = [...alphabetStore];
      setSlideshowExpanded(updatedTemp, previousLetterIndex, 5, -250);
      setalphabetStore(updatedTemp);
    }, 200);
  };

  let slideshowArrowStyle = { height: 120, width: 120 }
  if (isMobile) {
    slideshowArrowStyle = { height: 60, width: 60 }
  }

  const [alphabetsContainerStyle, setalphabetContainerStyle] = useState(isMobile ? {
    top: '55%',
    left: '50%'
  } : {
    top: '50%',
    left: '50%'
  })

  return (
    <div className="alpha">

      <Heading />

      <div className='alphabetsLayout'>
        <div className='alphabetsContainer' style={alphabetsContainerStyle}>
          {
            alphabetStore.map((x, i) => {
              // return <Alphabet props={{ onInfoClick: handleInfoClick, alphabet: x, slideshow: slideshow }} key={`alphabet${i}`} />
              return <Alphabet
                closeButtonClick={closeExpandedView}
                infoOnClick={expandLetter}
                alphabet={x}
                key={`alphabet${i}`}
                alphabetStore={alphabetStore}
                setalphabetStore={setalphabetStore}

              />
            })
          }
        </div>

      </div>
      <SlideshowArrow onClick={prevSlide} polyStyle={{ fill: 'white' }} style={{ display: isSlideshowMode ? 'block' : 'none', position: 'absolute', top: '45%', left: 0, ...slideshowArrowStyle }} />
      <SlideshowArrow onClick={nextSlide} polyStyle={{ fill: 'white' }} style={{ display: isSlideshowMode ? 'block' : 'none', position: 'absolute', top: '45%', right: -5, rotate: '180deg', ...slideshowArrowStyle }} />

      <div className="container">

        {
          boxes.map((x, i) =>
            <div
              key={'class' + i}
              className="box"
              onMouseEnter={() => box1Hover(boxes[i].name)}
              onMouseLeave={() => box1Hover(boxes[i].name)}
              onClick={() => boxHandler(boxes[i].name)}
            ><span className='boxSpan' style={{
              fontSize: finalBoxes[i].fontSize,
              color: finalBoxes[i].color
            }}>{finalBoxes[i].name}</span></div>
          )
        }

      </div>

    </div>
  );
}

export default HomePage;
